const parseBooleans = (x: any) => {
  for (const [key, value] of Object.entries(x)) {
    if (value instanceof Object) {
      Object.assign(x, { [key]: parseBooleans(value) });
    }
    
    if (/^true$/gi.test(value?.toString() || "")) {
      Object.assign(x, { [key]: true });
    } else if (/^false$/gi.test(value?.toString() || "")) {
      Object.assign(x, { [key]: false });
    }
  }

  return x;
};

const configFilePath =
  process.env.NODE_ENV === "production" ? `${process.env.PUBLIC_URL}/site-config/config.json` : `${process.env.PUBLIC_URL}/config.dev.json`;
const config = await fetch(configFilePath)
  .then(x => x.json())
  .then(x => {
    return parseBooleans(x);
  });

export default config;
