const MonthNames: {
  short: string[];
  full: string[];
} = {
  short: new Array(12),
  full: new Array(12)
};

const MonthOnlyFormat = new Intl.DateTimeFormat(undefined, { month: "short" });
const MonthOnlyFullFormat = new Intl.DateTimeFormat(undefined, { month: "long" });
for (let i = 0; i < 12; i++) {
  MonthNames.short[i] = MonthOnlyFormat.format(new Date(2000, i, 10));
  MonthNames.full[i] = MonthOnlyFullFormat.format(new Date(2000, i, 10));
}

const ShortDateFormat = new Intl.DateTimeFormat("default", { month: "short", day: "numeric", year: "numeric" });

class dateTimeHelper {
  MILLISECONDS_PER_SECOND: number = 1000;
  MILLISECONDS_PER_MINUTE: number = this.MILLISECONDS_PER_SECOND * 60;
  MILLISECONDS_PER_HOUR: number = this.MILLISECONDS_PER_MINUTE * 60;
  MILLISECONDS_PER_DAY: number = this.MILLISECONDS_PER_HOUR * 24;

  roundToDay(dateTime: Date, midnightIsYesterday: boolean = false): Date {
    const time = dateTime.getTime() - (midnightIsYesterday ? 1 : 0);
    return new Date(time - (time % this.MILLISECONDS_PER_DAY));
  }

  getDateOnly(dateTime: Date): string {
    return `${dateTime.getFullYear()}-${(dateTime.getMonth() + 1).toString().padStart(2, "0")}-${dateTime
      .getDate()
      .toString()
      .padStart(2, "0")}`;
  }

  getDifferenceInMonths(from: string | Date, to: string | Date) {
    const fromDate = typeof from === "string" ? new Date(from) : from;
    const toDate = typeof to === "string" ? new Date(to) : to;

    return (toDate.getFullYear() - fromDate.getFullYear()) * 12 + (fromDate.getMonth() - toDate.getMonth());
  }

  get monthNamesShort(): string[] {
    return MonthNames.short;
  }

  dateOnlyCheck(a: Date, b: Date, check: (x: number, y: number) => boolean): boolean {
    const dateOnlyA = new Date(a.getFullYear(), a.getMonth(), a.getDate()).getTime();
    const dateOnlyB = new Date(b.getFullYear(), b.getMonth(), b.getDate()).getTime();

    return check(dateOnlyA, dateOnlyB);
  }

  get today(): string {
    return this.getDateOnly(new Date());
  }

  get todayDate(): Date {
    const today = new Date();
    return new Date(today.setHours(0, 0, 0, 0));
  }

  get shortDateFormat(): Intl.DateTimeFormat {
    return ShortDateFormat;
  }
}

const helper: dateTimeHelper = new dateTimeHelper();

export default helper;
