import { LegacyDealApi } from "api/legacyApi";
import { RootState } from "./configureStore";
import { createAppSelector } from "./hooks";
import { ApiClients } from "api/clients";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoadedData } from "./loadedData";
import dateTimeHelper from "helpers/dateTimeHelper";
import { KeyValue } from "sagas/types/config";

const PageSize = 100;

export const selectTraderById = createAppSelector(
  [(state: RootState) => state.ConfigSagaState.InternalDetails.reply?.Traders, (_: RootState, traderId: number) => traderId],
  (traders, traderId) => traders?.find(x => x.id === traderId),
  {
    memoizeOptions: {
      resultEqualityCheck: (a?: KeyValue<number>, b?: KeyValue<number>) => a === b || a?.id === b?.id
    }
  }
);

export const selectBroker = createAppSelector(
  [(state: RootState) => state.ConfigSagaState.DefaultFees.reply?.Brokers, (_: RootState, brokerId: number) => brokerId],
  (defaultFees, brokerId) => defaultFees?.find(x => x.Name.id === brokerId)
);

export const selectClearer = createAppSelector(
  [(state: RootState) => state.ConfigSagaState.DefaultFees.reply?.Clearers, (_: RootState, clearerId: number) => clearerId],
  (defaultFees, clearerId) => defaultFees?.find(x => x.Name.id === clearerId)
);

export const selectUserNotifications = createAppSelector(
  [
    (state: RootState) => state.UserData.notifications.data,
    (state: RootState) => state.UserData.notifications.loading,
    (state: RootState) => state.UserData.allNotifications
  ],
  (data, loading, allItems) => ({
    electricity: allItems?.electricity || [],
    environment: allItems?.environment || [],
    gas: allItems?.gas || [],
    loading: loading,
    page: { current: data?.Page || 1, total: data?.TotalPages || 1, totalItems: data?.TotalCount || 0 }
  })
);

export const loadNotifications = createAsyncThunk<LegacyDealApi.NotificationResponse, number, { state: RootState }>(
  "internalConfig/loadNotifications",
  async (page, g) => {
    const response = await ApiClients.legacy.configApi.getAllNotifications(page, PageSize, g.signal);
    return response.Body || [];
  },
  {
    condition: (page, api) => {
      const { data, error, loading, lastLoaded } = api.getState().UserData.notifications;
      if (loading) return false;

      return page !== data.Page || (data.TotalCount < 1 && !error) || lastLoaded + dateTimeHelper.MILLISECONDS_PER_MINUTE * 1 < Date.now();
    }
  }
);

const initialState: {
  allNotifications: Record<string, LegacyDealApi.DealNotification[]>;
  notifications: LoadedData<LegacyDealApi.NotificationResponse>;
} = {
  allNotifications: {
    electricity: [],
    environment: [],
    gas: []
  },
  notifications: {
    data: { Page: 1, LastPage: true, TotalPages: 1, TotalCount: 0, Electricity: [], Environment: [], Gas: [] },
    lastLoaded: 0,
    loading: false,
    error: undefined
  }
};

const internalConfigSlice = createSlice({
  name: "internalConfig",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loadNotifications.pending, state => {
        state.notifications.loading = true;
      })
      .addCase(loadNotifications.fulfilled, (state, action) => {
        const loadedNotifications =
          action.payload.Page === 1
            ? {
                electricity: [],
                environment: [],
                gas: []
              }
            : state.allNotifications;

        state.allNotifications = {
          electricity: [
            ...loadedNotifications.electricity,
            ...(action.payload.Electricity || []).filter(x => !loadedNotifications.electricity.some(y => y.DealId === x.DealId))
          ],
          environment: [
            ...loadedNotifications.environment,
            ...(action.payload.Environment || []).filter(x => !loadedNotifications.environment.some(y => y.DealId === x.DealId))
          ],
          gas: [
            ...loadedNotifications.gas,
            ...(action.payload.Gas || []).filter(x => !loadedNotifications.gas.some(y => y.DealId === x.DealId))
          ]
        };
        state.notifications = {
          data: action.payload || [],
          error: undefined,
          lastLoaded: Date.now(),
          loading: false
        };
      })
      .addCase(loadNotifications.rejected, state => {
        state.notifications = {
          data: state.notifications.data || [],
          error: "An error occurred when loading your notifications.",
          lastLoaded: Date.now(),
          loading: false
        };
      });
  }
});

export default internalConfigSlice.reducer;
